<template>
  <v-footer
    padless
  >
    <v-card
      class="flex"
      flat
      tile
      color="bgBar"
    >
      <v-card-text class="py-2 text-center">
        {{ new Date().getFullYear() }} <strong>rukari.com</strong> {{ this.version }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  props: [
    'version',
  ],
};
</script>