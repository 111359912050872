<template>
  <v-dialog
    v-model="dialog"
    width="500"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="#d3381c"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-help-box</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="cardTitle">
        遊び方
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="mt-4">

        <p>
        <strong>市<span class="wordleGreen--text">区</span>町村</strong>は6回チャレンジできます。
        </p>

        <p>
        リストから市区町村を選んで
        <v-btn
          class=""
          fab
          dark
          x-small
          color="btnGuess"
        >
          <v-icon>mdi-map-marker-check</v-icon>
        </v-btn>
        を押してください。<br class="d-none d-sm-inline">
        漢字やひらがなを入力して絞り込むことができます。
        </p>

        <p>
        出題範囲は 1741市区町村（市792 東京23区 町743 村183）です。<br>
        <span class="caption">※2022年3月現在／北方領土に属する6村を含まず</span><br>
        </p>

        <p>
        予測の度に出題された市区町村への距離と方向がわかります。<br class="d-none d-sm-inline">
        都道府県が合っていればポイントボーナスが<strong class="wordleGreen--text">30%</strong>加算され円グラフ
        <v-progress-circular
          :rotate="360"
          :size="20"
          :width="6"
          :value="80"
          :color="'green'"
          class="mb-1"
        >
        </v-progress-circular>
        の色も変わります。
        </p>

        <v-divider class="my-4"></v-divider>

        <h3 class="text-center">例題</h3>

        <v-container class="px-0" style="max-width: 420px;">
          <answer-row
            :today="today"
            :options="options"
            :answer="answer[0]"
          >
          </answer-row>
        </v-container>

        <p>
        提示された<strong>「北海道 函館市」</strong>は出題から980kmも離れています。 ポイントも35%と低いですね。
        </p>

        <v-container class="px-0" style="max-width: 420px;">
          <answer-row
            :today="today"
            :options="options"
            :answer="answer[1]"
          >
          </answer-row>
        </v-container>

        <p>
        次に提示された<strong>「兵庫県 相生市」</strong>これはかなり近いっ! このポイントの高さは同じ都道府県ですよ!!
        </p>

        <v-container class="px-0" style="max-width: 420px;">
          <answer-row
            :today="today"
            :options="options"
            :answer="answer[2]"
          >
          </answer-row>
        </v-container>

        <p>
        おめでとう!! <strong>「兵庫県 南あわじ市」</strong>で正解です💮
        </p>

        <v-divider class="my-4"></v-divider>

        <h4>
        毎日、日本時間の0時に新しい市<span class="wordleGreen--text">区</span>町村が出題されます!
        </h4>

        <v-divider class="my-4"></v-divider>

        <p class="text-subtitle-1 mb-0">
          <a href="https://note.com/rukari/n/n3f068948a22a" class="text-decoration-none" target="_blank"><strong>市区町村 FAQ よくある質問と回答</strong> <v-icon small>mdi-open-in-new</v-icon></a><br>
        </p>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          とじる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AnswerRow from './AnswerRow.vue'

export default {
  components: { AnswerRow },
  props: [
    'today',
    'options'
  ],
  data () {
    return {
      dialog: false,
      answer: [{
          is_correct: false,
          is_correct_state: false,
          code: 1202,
          state: "北海道",
          name: "函館市",
          dist: 980,
          dir: 210,
        }, {
          is_correct: false,
          is_correct_state: true,
          code: 28208,
          state: "兵庫県",
          name: "相生市",
          dist: 63,
          dir: 153,
        }, {
          is_correct: true,
          is_correct_state: true,
          code: 28224,
          state: "兵庫県",
          name: "南あわじ市",
          dist: 0,
          dir: 361,
        }
      ]
    }
  },
}
</script>
