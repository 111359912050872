import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            bgBar: "#f8fbf8",
            wordleGreen: "#3eb370",
            bgMap: "#fff",
            map: "#2b2b2b",
            bgDirect: "#fff",
            bgAnswer: "#fef4f4",
            bgAnswerOutline: "#f5b1aa",
            bgEmpty: "#e9dfe5",
            btnGuess: "#3eb370",
            distr: "#9790a4",
            cardTitle: "#f5f5f5",
          },
          dark: {
            bgBar: "#2b2b2b",
            wordleGreen: "#3eb370",
            bgMap: "#121212",
            map: "#f8fbf8",
            bgDirect: "#000",
            bgAnswer: "#640125",
            bgAnswerOutline: "#a22041",
            bgEmpty: "#432f2f",
            btnGuess: "#3eb370",
            distr: "#9790a4",
            cardTitle: "#272727",
          },
        },
        options: { customProperties: true, variations: false },
    },
});
