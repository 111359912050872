<style>
  .answer-v-card {
    background-color: var(--v-bgAnswer-base) !important;
    border: 2px solid var(--v-bgAnswerOutline-base) !important;
  }
</style>

<template>
  <v-row class="py-1 px-1" style="height: 2.5rem">
    <v-col cols="3" class="pa-0 pe-1">
      <v-card
        flat
        height="2rem"
        class="d-flex align-center justify-center answer-v-card"
        >{{ answer.state }}</v-card
      >
    </v-col>
    <v-col cols="4" class="pa-0 pe-1">
      <v-card
        flat
        height="2rem"
        class="d-flex align-center justify-center answer-v-card"
        >{{ answer.name }}</v-card
      >
    </v-col>
    <v-col cols="2" class="pa-0 pe-1">
      <v-card
        flat
        height="2rem"
        class="d-flex align-center justify-center answer-v-card"
      >
        <span v-if="options.unit === 'km'"
          >{{ answer.dist }}<span class="text-caption">km</span></span
        >
        <span v-if="options.unit === 'li'"
          >{{ Math.floor(answer.dist / 3.927)
          }}<span class="text-caption">里</span></span
        >
      </v-card>
    </v-col>
    <v-col cols="1" class="pa-0 pe-1">
      <v-card
        flat
        color="bgDirect"
        height="2rem"
        class="d-flex align-center justify-center"
      >
        <direction-icon v-bind:dir="answer.dir" :today="today"></direction-icon>
      </v-card>
    </v-col>
    <v-col cols="2" class="pa-0">
      <v-card
        flat
        height="2rem"
        class="d-flex align-center justify-center answer-v-card"
      >
        <v-progress-circular
          v-show="rate < 100"
          :rotate="360"
          :size="20"
          :width="6"
          :value="rate"
          :color="answer.is_correct_state ? 'green' : 'amber'"
        >
        </v-progress-circular>
        <span class="ps-1">{{ rate }}%</span>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DirectionIcon from "./DirectionIcon.vue";

export default {
  props: [
    'today',
    'options',
    'answer'
  ],
  components: {
    'direction-icon': DirectionIcon,
  },
  data () {
    return {
      rate: this.calcRate(this.answer)
    }
  },
};
</script>
