<template>
  <v-dialog
    v-model="dialog"
    width="500"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="#e6b422"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-chart-box</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="cardTitle">
        統計
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-1 pb-2">

        <v-container>
          <v-row
            no-gutters
          >
            <v-spacer class="d-none d-sm-block"></v-spacer>
            <v-col>
              <v-card flat>
                <v-card-title class="px-0 justify-center">{{ records.challenge }}</v-card-title>
                <v-card-subtitle class="px-0 text-center">解答数</v-card-subtitle>
              </v-card>
            </v-col>
            <v-col>
              <v-card flat>
                <v-card-title class="px-0 justify-center">{{ records.challenge > 0 ? Math.floor(records.win * 100 / records.challenge) : 0 }}</v-card-title>
                <v-card-subtitle class="px-0 text-center">正答率 %</v-card-subtitle>
              </v-card>
            </v-col>
            <v-col>
              <v-card flat>
                <v-card-title class="px-0 justify-center">{{ records.streak }}</v-card-title>
                <v-card-subtitle class="px-0 text-center">連続<br>正答中</v-card-subtitle>
              </v-card>
            </v-col>
            <v-col>
              <v-card flat>
                <v-card-title class="px-0 justify-center">{{ records.maxStreak }}</v-card-title>
                <v-card-subtitle class="px-0 text-center">連続<br>正答記録</v-card-subtitle>
              </v-card>
            </v-col>
            <v-spacer class="d-none d-sm-block"></v-spacer>
          </v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            正答分布
          </v-col>
          <v-row
            v-for="(trial, index) in records.trial"
            v-bind:key="index"
          >            
            <v-spacer class="d-none d-sm-block"></v-spacer>
            <v-col
              cols="auto"
              class="pa-1"
            >
              {{ (index + 1) }}
            </v-col>
            <v-col
              sm="8"
              class="pa-1"
            >
              <v-progress-linear
                :value="Math.floor(trial * 100 / Math.max(1, Math.max(...records.trial)))"
                height="25"
                color="distr"
                background-opacity="0.1"
              >
                <strong>{{ trial }}</strong>
              </v-progress-linear>
            </v-col>
            <v-spacer class="d-none d-sm-block"></v-spacer>
          </v-row>
        </v-container>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          とじる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: [
      'records',
    ],
    data () {
      return {
        dialog: false,
      }
    },
  }
</script>
