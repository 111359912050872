<template>
  <img draggable="false" class="emoji" v-bind:alt="alt" v-bind:src="src" style="width:1.5em;height:1.5em;vertical-align:middle;">
</template>

<script>
export default {
  props: [
    'today',
    'dir'
  ],
  data: () => ({
    alt: "",
    src: "",
    index: 0,
    icons: ["2b06","2197","27a1","2198","2b07","2199","2b05","2196","2b06","1f4ae"],
    anniversaryIcons: {
      "01-01": "1f305", // 元日
      "01-07": "1f33f", // 七草の節句
      "02-11": "1f38c", // 建国記念日
      "03-03": "1f38e", // 桃の節句
      "04-03": "1f382", // 市区町村サービスイン
      "04-19": "1f5fa", // 地図の日
      "04-22": "1f6bb", // 道の駅の日
      "04-25": "1f5fe", // 市町村制公布記念日(ペンギンの日)
      "05-05": "1f38f", // 端午の節句
      "05-10": "1f3d8", // 街区表示板の日
      "05-16": "1f9f3", // 旅の日
      "06-03": "1f310", // 測量の日
      "06-26": "1f9ed", // オリエンテーリングの日
      "07-01": "1f6f0", // ナビの日
      "07-07": "1f38b", // 笹の節句
      "08-10": "1f6e4", // 道の日
      "09-09": "1f376", // 菊の節句
      "09-20": "1f68c", // バスの日(空の日)
      "10-14": "1f6e4", // 鉄道の日
      "10-31": "1f383", // ハロウィン
      "12-17": "2708", // 飛行機の日
      "12-25": "1f384" // クリスマス
    },
    url: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.1/assets/72x72/"
  }),
  created : function(){
    if (this.dir <= 360) {
      this.index = Math.floor((this.dir + 22.5) / 45)
      this.src = this.url + this.icons[ this.index ] + ".png"
      this.alt = this.emojis[ this.index ]
    }
    else {
      let icon = this.icons[ 9 ]
      this.alt = this.emojis[ 9 ]
      const date = this.today.format('MM-DD')
      if (date in this.anniversaryIcons) {
        icon = this.anniversaryIcons[ date ]
        this.alt = this.anniversaryEmojis[ date ]
      }
      this.src = this.url + icon + ".png"
    }
  }
}
</script>
