var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"bgBar"}},[_c('about-dialog',{attrs:{"version":_vm.version}}),_c('help-dialog',{attrs:{"today":_vm.today,"options":_vm.options}}),_c('v-spacer'),_c('h1',{staticClass:"text-center"},[_c('p',{staticClass:"text-h5 font-weight-black ma-0"},[_vm._v(" 市"),_c('span',{staticClass:"wordleGreen--text"},[_vm._v("区")]),_vm._v("町村 ")]),_c('p',{staticClass:"text-body-2 ma-0"},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Shi "),_c('span',{staticClass:"wordleGreen--text"},[_vm._v("Ku")]),_vm._v(" Cho Son - ")]),_vm._v("Municipalities of Japan. ")])]),_c('v-spacer'),_c('stat-dialog',{attrs:{"records":_vm.records}}),_c('options-dialog',{attrs:{"options":_vm.options},on:{"unit":_vm.setUnit,"theme":_vm.setTheme}})],1),_c('v-main',[_c('map-container',{attrs:{"code":_vm.correctCode,"item":_vm.correctItem}}),_c('v-container',{staticClass:"text-center",staticStyle:{"max-width":"420px"}},[_vm._l((_vm.answers),function(answer){return _c('answer-row',{key:answer.code,attrs:{"today":_vm.today,"options":_vm.options,"answer":answer}})}),_vm._l((6 - _vm.answers.length),function(n){return _c('empty-row',{key:n})}),(_vm.isGuessEnable())?_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"pt-5 mb-0",attrs:{"items":_vm.shikuchoson,"item-text":(item) =>
                item.state +
                ' ' +
                item.name +
                ' (' +
                item.state_ruby +
                ' ' +
                item.ruby +
                ')',"item-value":"code","label":"市区町村","outlined":""},model:{value:(_vm.answerCode),callback:function ($$v) {_vm.answerCode=$$v},expression:"answerCode"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-n4 mb-5 align-center",attrs:{"fab":"","dark":"","large":"","color":"btnGuess"},on:{"click":_vm.guess}},[_c('v-icon',[_vm._v("mdi-map-marker-check")])],1)],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.copyToClipboard}},[_c('v-icon',{staticClass:"me-1"},[_vm._v("mdi-share-variant-outline")]),_vm._v(" 結果をシェアする ")],1)],1),_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"href":_vm.googleMapUrl,"target":"_blank","block":"","color":"info"}},[_c('v-icon',{staticClass:"me-1"},[_vm._v("mdi-map-marker-question-outline")]),_vm._v(" Google Mapで確認する ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"href":_vm.wikipediaUrl,"target":"_blank","block":"","color":"info"}},[_c('v-icon',{staticClass:"me-1"},[_vm._v("mdi-wikipedia")]),_vm._v(" Wikipediaで確認する ")],1)],1)],1)],2)],1),_c('faq-container'),_c('rakuten-container'),_c('my-footer',{attrs:{"version":_vm.version}}),_c('notifications',{staticClass:"my-notification",attrs:{"group":"default","position":"top center","width":"360"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }