<template>
  <v-dialog
    v-model="dialog"
    width="500"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="#007bbb"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-cog-box</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="cardTitle">
        設定
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row
            align="center"
          >

            <v-col cols="12">
              <v-select
                v-model="unit"
                :items="[{text:'km', value:'km'}, {text:'里（3.927km）', value:'li'}]"
                label="距離の単位"
                prepend-icon="mdi-map-marker-distance"
                persistent-hint
                outlined
                required
                class="pt-4"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="theme"
                label="テーマ"
                :prepend-icon="themeIcon"
              >
              </v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >
          とじる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: [
      'options',
    ],
    data() {
      return {
        dialog: false,
        unit: this.options.unit,
        theme: this.options.theme,
      }
    },
    computed: {
      themeIcon() {
        return this.theme ? 'mdi-weather-night' : 'mdi-weather-sunny'
      }
    },
    watch: {
      unit() {
        this.$emit('unit', this.unit)
      },
      theme() {
        this.$emit('theme', this.theme)
      }
    },
  }
</script>
