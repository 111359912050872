<template>
  <v-dialog
    v-model="dialog"
    width="500"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="#d3cbc6"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="cardTitle">
        市区町村 について
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="mt-4">
        <p>
          <strong>市<span class="wordleGreen--text">区</span>町村</strong>は、<a href="https://rukari.com/" class="text-decoration-none" target="_blank">rukari.com <v-icon small>mdi-open-in-new</v-icon></a> / <a href="https://twitter.com/rukari" class="text-decoration-none" target="_blank">@rukari <v-icon small>mdi-open-in-new</v-icon></a>によって開発、運営されています。
          主な中の人は、ひであ / <a href="https://twitter.com/hidea" class="text-decoration-none" target="_blank">@hidea <v-icon small>mdi-open-in-new</v-icon></a>です。<br>
        </p>
        <p>
          告知・サポートは𝕏アカウント <strong>市区町村 / <a href="https://x.com/shikuchoson" class="text-decoration-none" target="_blank">@shikuchoson <v-icon small>mdi-open-in-new</v-icon></a></strong>をご覧ください。
        </p>
        <p class="mb-0">
          ShiKuChoSon ver. {{ version }}
        </p>
      </v-card-text>

      <v-card-title>
        謝辞
      </v-card-title>

      <v-card-text>
        <p>
          直接影響を受けた<strong>WORLDLE</strong>を製作した<a href="https://twitter.com/teuteuf" class="text-decoration-none" target="_blank">@teuteuf <v-icon small>mdi-open-in-new</v-icon></a>氏と、全ての源泉である<strong>Wordle</strong>を製作した Josh Wardle / <a href="https://twitter.com/powerlanguish" class="text-decoration-none" target="_blank">@powerlanguish <v-icon small>mdi-open-in-new</v-icon></a>氏に心からの感謝と深い敬意を表します。
        </p>

        <p>
          <span>Pay deeply respect to <strong>WORLDLE</strong> & <strong>Wordle</strong>.</span><br>
          <span>`Shi<span class="wordleGreen--text">Ku</span>ChoSon` has been super heavily inspired by <strong>WORLDLE</strong> ( has been heavily inspired by <strong>Wordle</strong> created by Josh Wardle / <a href="https://twitter.com/powerlanguish" class="text-decoration-none" target="_blank">@powerlanguish <v-icon small>mdi-open-in-new</v-icon></a>.) created  by <a href="https://twitter.com/teuteuf" class="text-decoration-none" target="_blank">@teuteuf <v-icon small>mdi-open-in-new</v-icon></a>.</span>
        </p>

        <p class="text-subtitle-1 mb-0">
          <a href="https://worldle.teuteuf.fr/" class="text-decoration-none" target="_blank"><strong>WORLDLE</strong> <v-icon small>mdi-open-in-new</v-icon></a><br>
          <a href="https://www.nytimes.com/games/wordle/index.html" class="text-decoration-none" target="_blank"><strong>Wordle</strong> - The New York Times <v-icon small>mdi-open-in-new</v-icon></a>
        </p>

      </v-card-text>

      <v-card-title>
        出典・利用API
      </v-card-title>

      <v-card-text>

        <p>
        1741市区町村（市792 東京23区 町743 村183）が対象となります。<br>
        <span class="caption ">※2022年3月現在／北方領土に属する6村を含まず</span><br>
        <a href="https://www.soumu.go.jp/" class="text-decoration-none" target="_blank">総務省</a> / <a href="https://twitter.com/MIC_JAPAN" class="text-decoration-none" target="_blank">@MIC_JAPAN</a><br>
        <a href="https://www.soumu.go.jp/kouiki/kouiki.html" class="text-decoration-none" target="_blank">政策 > 地方行財政 > 地方自治制度 > 広域行政・市町村合併</a><br>
        </p>

        <p>
        市区町村の地形図は Geolonia 様が提供されている以下のAPIを利用しています。<br>
        <a href="https://github.com/geolonia/japanese-admins" class="text-decoration-none" target="_blank">GeoJSON endpoints for Japanese administrations <v-icon small>mdi-github</v-icon></a><br>
        Copyright 2020 <a href="https://geolonia.com/" class="text-decoration-none" target="_blank">Geolonia</a> Inc. / <a href="https://twitter.com/geoloniamap" class="text-decoration-none" target="_blank">@geoloniamap</a><br>
        </p>

        <p class="mb-1">
        市区町村の代表点は アマノ技研様の以下のデータを利用しています。<br>
        <a href="https://amano-tec.com/" class="text-decoration-none" target="_blank">アマノ技研</a> / <a href="https://twitter.com/AmanoSTI" class="text-decoration-none" target="_blank">@AmanoSTI</a><br>
        <a href="https://amano-tec.com/data/localgovernments.html" class="text-decoration-none" target="_blank">地理空間データ Data | 地方公共団体の位置データ Location Data of Local Governments in Japan</a><br>
        </p>
        <div class="caption">
          ※この内、管轄する市区町村と異なる場所に本庁機能を置いている以下の3町村の代表点は各域内の代表的と思われる位置に変更しています。<br>
          <div class="ps-2">
            鹿児島県 三島村 → 大里出張所<br>
            鹿児島県 十島村 → 中之島出張所<br>
            沖縄県 竹富町 → 西表島大原出張所
          </div>
        </div>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          とじる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: [
      'version',
    ],
    data () {
      return {
        dialog: false,
      }
    },
  }
</script>
