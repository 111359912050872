export default {
  data: () => ({
    blocks: [
      "⬜⬜⬜⬜⬜",
      "🟨⬜⬜⬜⬜",
      "🟩⬜⬜⬜⬜",
      "🟩🟨⬜⬜⬜",
      "🟩🟩⬜⬜⬜",
      "🟩🟩🟨⬜⬜",
      "🟩🟩🟩⬜⬜",
      "🟩🟩🟩🟨⬜",
      "🟩🟩🟩🟩⬜",
      "🟩🟩🟩🟩🟨",
      "🟩🟩🟩🟩🟩",
    ],
    emojis: ["⬆️","↗️","➡️","↘️","⬇️","↙️","⬅️","↖️","⬆️","💮"],
    anniversaryEmojis: {
      "01-01": "🌅", // 元日
      "01-07": "🥬", // 七草の節句
      "02-11": "🎌", // 建国記念日
      "03-03": "🎎", // 桃の節句
      "04-03": "🎂", // 市区町村サービスイン
      "04-19": "🗺", // 地図の日
      "04-22": "🚻", // 道の駅の日
      "04-25": "🗾", // 市町村制公布記念日(ペンギンの日)
      "05-05": "🎏", // 端午の節句
      "05-10": "🏘️", // 街区表示板の日
      "05-16": "🧳", // 旅の日
      "06-03": "🌐", // 測量の日
      "06-26": "🧭", // オリエンテーリングの日
      "07-01": "🛰️", // ナビの日
      "07-07": "🎋", // 笹の節句
      "07-28": "🏞️", // 地名の日
      "08-10": "🛣️", // 道の日
      "09-09": "🍶", // 菊の節句
      "09-20": "🚌", // バスの日(空の日)
      "09-27": "🧳", // 世界観光記念日
      "10-14": "🛤️", // 鉄道の日
      "10-31": "🎃", // ハロウィン
      "12-17": "🛫", // 飛行機の日
      "12-25": "🎄" // クリスマス
    },
  }),
  methods: {
    calcRate(answer) {
      return (
        Math.floor(
          answer.dist < 2000 ? ((2000 - answer.dist) * 70) / 2000 : 0
        ) + (answer.is_correct_state ? 30 : 0)
      );
    },
    rateBlock(rate) {
      return this.blocks[ Math.floor(Math.min(Math.max(rate, 0), 100) / 10) ]
    },
    dirEmoji(answer, today) {
      if (answer.dir <= 360) {
        const index = Math.floor((answer.dir + 22.5) / 45)
        return this.emojis[ index ]
      }
      else {
        const date = today.format('MM-DD')
        if (date in this.anniversaryEmojis) {
          return this.anniversaryEmojis[ date ]
        }
        return this.emojis[ 9 ]
      }
    }
  },
};
