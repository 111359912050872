import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import mixinsCommon from './mixins/common' 

//import './registerServiceWorker'
import { unregister } from 'register-service-worker'
unregister()

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.mixin(mixinsCommon)

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
