<style>
  #map-container svg {
    background-color: var(--v-bgMap-base) !important;
  }
  #map-container svg .geojson {
    stroke: var(--v-map-base) !important;
    fill: var(--v-map-base) !important;
  }
</style>

<template>
  <v-container
    class="align-center pa-0"
    style="max-width: var(--max-width);"
    :style="styleContainer"
  >
    <v-card
      :height="height"
      flat
    >
      <div id="map-container"></div>
    </v-card>
  </v-container>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    code: {
      required: true
    },
    item: {
      required: true,
    },
    width: {
      default: 400
    },
    height: {
      default: 200
    }
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    styleContainer() {
      return {
        '--max-width' : this.width + 'px',
      }
    },
  },
  async created() {
    this.loading = true

    const url   = "https://geolonia.github.io/japanese-admins/"

    const state = Math.floor(this.code / 1000)
    const city  = this.code % 1000
    const area  = this.item.area

    let getjson = null
    if (area.length > 0) {
      for (const offset of area) {
        const path = url
          + String(state).padStart(2, '0')  
          + "/"
          + String(state).padStart(2, '0')
          + (city + offset)
          + ".json"

        const response = await fetch(path)
        const json = await response.json()
        if (getjson === null) {
          getjson = json
        }
        else {
          for (const feature of json["features"]) {
            getjson["features"].push(feature)
          }
        }
      }
    }
    else {
        const path = url
          + String(state).padStart(2, '0')  
          + "/" 
          + String(state).padStart(2, '0')
          + city
          + ".json"

        const response = await fetch(path)
        getjson = await response.json()
    }

    const width = 800;  // 描画サイズ: 幅
    const height = 400; // 描画サイズ: 高さ

    const bounds = d3.geoBounds(getjson)
    // const center = d3.geoCentroid(getjson) // これは重心
    const center = [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2];
    const distance = d3.geoDistance(bounds[0], bounds[1])
    const scale = height / distance / Math.sqrt(2)

    // 地図の投影設定
    const projection = d3.geoMercator()
      .translate([width / 2, height / 2])
      .center(center)
      .scale(scale);
    // 地図をpathに投影(変換)
    const path = d3.geoPath().projection(projection);
    
    // SVG要素を追加
    const svg = d3.select(`#map-container`)
      .append(`svg`)
      .attr(`viewBox`, `0 0 ${width} ${height}`)
      .attr(`width`, `100%`)
      .attr(`height`, `100%`);

    // 領域データをpathで描画
    svg.selectAll(`path`)
      .data(getjson.features)
      .enter()
      .append(`path`)
      .attr(`d`, path)
      .attr(`stroke-width`, 2)
      .attr(`fill-opacity`, 1)
      .attr("class", "geojson");

    this.loading = false;
  },
};
</script>
